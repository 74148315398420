/*******************************************
 * 
 * Configurations Variables
 * 
 ******************************************/

@use 'sass:math';

$enable-negative-margins: true;

$archivo-font-path:			'../../../public/media/fonts/archivo/';

:root {

	--var-f-primary:					'Archivo-Variable';

	/* Font weight */
	--var-f-thin:					100.0;
	--var-f-regular:				400.0;
	--var-f-black:					900.0;


	// colori
	--var-c-primary:				#FABD16; // giallo
	--var-c-secondary:				#5200FF; // bluette

	--var-c-white:					#ffffff;
	--var-c-black:					#000000;

	--bs-border-color:				#ffffff !important;
}


$base_font_size: 16px;
@function rem($value) {
	@return math.div($value, $base_font_size) * 1rem;
}

@function vw($px-vw, $base-vw: 1320px) {
	@return math.div($px-vw, $base-vw) * 100vw;
}

@mixin letter-spacing($letter-spacing) {
    letter-spacing: calc($letter-spacing / 1000) + em;
}
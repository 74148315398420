/*******************************************
 *
 * Common Classes
 *
 ******************************************/


html {
	height: 100%;
	box-sizing: border-box;
}

//body
body {
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: none;
	background-color: var(--var-c-primary);
	font-family: var(--var-f-primary);
	color: var(--var-c-white);
	text-transform: uppercase;
}


header {
	position: relative;
}


@include media-breakpoint-up(md) {
	[data-cursor] {
		width: rem(50px);
		height: rem(50px);
		background: linear-gradient(120deg, #247BA0, #E7E6F7, #C6D2ED, #210124, #750D37);
		background-size: 1600% 1600%;
		mix-blend-mode: difference;
		pointer-events: none;
		z-index: 50;
		transition: 0.15s linear;
		animation: blobRadius 5s ease infinite, blobBackground 15s ease infinite;
	}
}

@keyframes blobRadius {
	0%, 100% {
		border-radius: 43% 77% 80% 40%/40% 40% 80% 80%;
	}
	20% {
		border-radius: 47% 73% 61% 59%/47% 75% 45% 73%;
	}
	40% {
		border-radius: 46% 74% 74% 46%/74% 58% 62% 46%;
	}
	60% {
		border-radius: 47% 73% 61% 59%/40% 40% 80% 80%;
	}
	80% {
		border-radius: 50% 70% 52% 68%/51% 61% 59% 69%;
	}
}
@keyframes blobBackground {
	0%, 100% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
}
.cursor-hover--a [data-cursor] {
	width: rem(100px);
	height: rem(100px);
}


@keyframes jumpAround {
	0%, 100% {
		top: rem(10px);
	}
	50% {
		top: rem(20px);
	}
}

// bg change
.page-transition {
	position: absolute;
	top: rem(10px);
	right: rem(10px);
	animation: jumpAround 1s alternate infinite;

	.ball {
		width: rem(20px);
		height: rem(20px);
		background: var(--var-c-white);
		border-radius: 100%;
	}

	&:hover {
		animation: none;
	}
}

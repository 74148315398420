/**
 * @license
 *
 * Font Family: Archivo
 * Designed by: Omnibus-Type
 * URL: https://www.fontshare.com/fonts/archivo
 * © 2023 Indian Type Foundry
 *
 * Font Styles:
 * Archivo Variable(Variable font)
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 600.0;
*
* available axes:

* 'wght' (range from 100.0 to 900.0)

*/

@font-face {
  font-family: 'Archivo-Variable';
  src: url('#{$archivo-font-path}Archivo-Variable.woff2') format('woff2'),
       url('#{$archivo-font-path}Archivo-Variable.woff') format('woff'),
       url('#{$archivo-font-path}Archivo-Variable.ttf') format('truetype');
       font-weight: 100 900;
       font-display: swap;
       font-style: normal;
}
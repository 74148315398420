/* -------------------------------------------------------------
  Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
  By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets

  List of CSS3 Sass Mixins File to be @imported and @included as you need

  The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
  ENDING css files

  note: All CSS3 Properties are being supported by Safari 5
  more info: http://www.findmebyip.com/litmus/#css3-properties

------------------------------------------------------------- */


////
/// @author Matthieu Aussaguel
/// @group sass-css3-mixins
////


/// Adds a browser prefix to the property
/// @param {*} $property Property
/// @param {*} $value Value

@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
   -khtml-#{$property}: #{$value};
   -moz-#{$property}: #{$value};
    -ms-#{$property}: #{$value};
     -o-#{$property}: #{$value};
      #{$property}: #{$value};
}


/// Background Gradient
/// @param {Color} $startColor [#3C3C3C] - Start Color
/// @param {Color} $endColor [#999999] - End Color

@mixin background-gradient($startColor: #3C3C3C, $endColor: #999999) {
  background-color: $startColor;
  background-image: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
  background-image: -webkit-linear-gradient(top, $startColor, $endColor);
  background-image:    -moz-linear-gradient(top, $startColor, $endColor);
  background-image:     -ms-linear-gradient(top, $startColor, $endColor);
  background-image:      -o-linear-gradient(top, $startColor, $endColor);
  background-image:         linear-gradient(top, $startColor, $endColor);
  filter:            progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}');
}


/// Background Horizontal
/// @param {Color} $startColor [#3C3C3C] - Start Color
/// @param {Color} $endColor [#999999] - End Color

@mixin background-horizontal($startColor: #3C3C3C, $endColor: #999999) {
  background-color: $startColor;
  background-image: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
  background-image: -webkit-linear-gradient(left, $startColor, $endColor);
  background-image:    -moz-linear-gradient(left, $startColor, $endColor);
  background-image:     -ms-linear-gradient(left, $startColor, $endColor);
  background-image:      -o-linear-gradient(left, $startColor, $endColor);
  background-image:         linear-gradient(left, $startColor, $endColor);
  filter:            progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}', gradientType='1');
}


/// Background Radial
/// @param {Color} $startColor [#3C3C3C] - Start Color
/// @param {Percentage} $startPos [0%] - Start position
/// @param {Color} $endColor [#999999] - End Color
/// @param {Percentage} $endPos [100%] - End position

@mixin background-radial($startColor: #FFFFFF, $startPos: 0%, $endColor: #000000, $endPos:100%) {
  background: -moz-radial-gradient(center, ellipse cover, $startColor $startPos, $endColor $endPos);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop($startPos,$startColor), color-stop($endPos,$endColor));
  background: -webkit-radial-gradient(center, ellipse cover, $startColor $startPos,$endColor $endPos);
  background: -o-radial-gradient(center, ellipse cover, $startColor $startPos,$endColor $endPos);
  background: -ms-radial-gradient(center, ellipse cover, $startColor $startPos,$endColor $endPos);
  background: radial-gradient(ellipse at center, $startColor $startPos,$endColor $endPos);
}


/// Background Size
/// @param {Size} $width [100%] - Width
/// @param {Size} $width [$width] - Height
/// @require {mixin} css3-prefix

@mixin background-size($width: 100%, $height: $width) {
  @if type-of($width) == 'number' and $height != null {
  @include css3-prefix('background-size', $width $height);
  } @else {
  @include css3-prefix('background-size', $width);
  }
}


/// Background Color Opacity
/// @param {Color} $color [100%] - Color
/// @param {Double} $opacity [0.85] - Opacity

@mixin background-opacity($color: #000, $opacity: 0.85) {
  background: $color;
  background: rgba($color, $opacity);
}


/// Border Radius
/// @param {Size} $radius [5px] - Radius
/// @require {mixin} css3-prefix

@mixin border-radius($radius: 5px) {
  @include css3-prefix('border-radius', $radius);
}


/// Border Radius Separate
/// @param {Size} $topLeftRadius [5px] - Top Left
/// @param {Size} $topRightRadius [5px] - Top Right
/// @param {Size} $bottomLeftRadius [5px] - Bottom Left
/// @param {Size} $bottomRightRadius [5px] - Bottom Right

@mixin border-radius-separate($topLeftRadius: 5px, $topRightRadius: 5px, $bottomLeftRadius: 5px, $bottomRightRadius: 5px) {
  -webkit-border-top-left-radius:     $topLeftRadius;
  -webkit-border-top-right-radius:    $topRightRadius;
  -webkit-border-bottom-right-radius: $bottomRightRadius;
  -webkit-border-bottom-left-radius:  $bottomLeftRadius;

  -moz-border-radius-topleft:     $topLeftRadius;
  -moz-border-radius-topright:    $topRightRadius;
  -moz-border-radius-bottomright: $bottomRightRadius;
  -moz-border-radius-bottomleft:  $bottomLeftRadius;

  border-top-left-radius:     $topLeftRadius;
  border-top-right-radius:    $topRightRadius;
  border-bottom-right-radius: $bottomRightRadius;
  border-bottom-left-radius:  $bottomLeftRadius;
}


/// Box
/// @param {*} $orient [horizontal] - Orientation
/// @param {*} $pack [center] - Pack
/// @param {*} $align [center] - Align
/// @require {mixin} css3-prefix

@mixin box($orient: horizontal, $pack: center, $align: center) {
  display: -webkit-box;
  display: -moz-box;
  display: box;

  @include css3-prefix('box-orient', $orient);
  @include css3-prefix('box-pack', $pack);
  @include css3-prefix('box-align', $align);
}


/// Box RGBA
/// @param {Integer} $r [60] - Red
/// @param {Integer} $g [3] - Green
/// @param {Integer} $b [12] - Blue
/// @param {Double} $opacity [0.23] - Opacity
/// @param {Color} $color [#3C3C3C] - Color

@mixin box-rgba($r: 60, $g: 3, $b: 12, $opacity: 0.23, $color: #3C3C3C) {
  background-color: transparent;
  background-color: rgba($r, $g, $b, $opacity);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color}',endColorstr='#{$color}');
      zoom:   1;
}


/// Box Shadow
/// @param {Size} $x [2px] - X
/// @param {Size} $y [2px] - Y
/// @param {Size} $blur [5px] - Blur
/// @param {Color} $color [rgba(0,0,0,.4)] - Color
/// @param {Boolean} $inset - Inset

@mixin box-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4), $inset: "") {
  @if ($inset != "") {
  @include css3-prefix('box-shadow', $inset $x $y $blur $color);
  } @else {
  @include css3-prefix('box-shadow', $x $y $blur $color);
  }
}


/// Box Sizing
/// @param {*} $type [border-box] - Type
/// @require {mixin} css3-prefix

@mixin box-sizing($type: border-box) {
  @include css3-prefix('box-sizing', $type);
}


/// Columns
/// @param {Integer} $count [3] - Count
/// @param {Integer} $gap [10] - Gap
/// @require {mixin} css3-prefix

@mixin columns($count: 3, $gap: 10) {
  @include css3-prefix('column-count', $count);
  @include css3-prefix('column-gap', $gap);
}


/// Double Borders
/// @param {Color} $colorOne [#3C3C3C] - Color One
/// @param {Color} $colorTwo [#999999] - Color Two
/// @param {Size} $radius [0] - Radius
/// @require {mixin} css3-prefix
/// @require {mixin} border-radius

@mixin double-borders($colorOne: #3C3C3C, $colorTwo: #999999, $radius: 0) {
  border: 1px solid $colorOne;

  @include css3-prefix('box-shadow', 0 0 0 1px $colorTwo);

  @include border-radius( $radius );
}


/// Flex
/// @param {Integer} $value [1] - Value
/// @require {mixin} css3-prefix

@mixin flex($value: 1) {
  @include css3-prefix('box-flex', $value);
}


/// Flip
/// @param {Double} $scaleX [-1] - ScaleX
/// @require {mixin} css3-prefix

@mixin flip($scaleX: -1) {
  @include css3-prefix('transform', scaleX($scaleX));
  filter:            FlipH;
  -ms-filter:        "FlipH";
}


/// Font Face
/// @param {Font} $fontFamily [myFont] - Font Family
/// @param {String} $eotFileSrc ['myFont.eot'] - Eot File Source
/// @param {String} $woffFileSrc ['myFont.woff'] - Woff File Source
/// @param {String} $ttfFileSrc ['myFont.ttf'] - Ttf File Source
/// @param {String} $svgFileSrc ['myFont.svg'] - Svg File Source

@mixin font-face($fontFamily: myFont, $eotFileSrc: 'myFont.eot', $woffFileSrc: 'myFont.woff', $ttfFileSrc: 'myFont.ttf', $svgFileSrc: 'myFont.svg', $svgFontID: '#myFont') {
  font-family: $fontFamily;
  src: url($eotFileSrc)  format('eot'),
     url($woffFileSrc) format('woff'),
     url($ttfFileSrc)  format('truetype'),
     url($svgFileSrc + $svgFontID) format('svg');
}


/// Opacity
/// @param {Double} $opacity [0.5] - Opacity
/// @require {mixin} css3-prefix

@mixin opacity($opacity: 0.5) {
  $opacityMultiplied: ($opacity * 100);

  filter:         alpha(opacity=$opacityMultiplied);
  -ms-filter:     "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacityMultiplied + ")";
  @include css3-prefix('opacity', $opacity);
}


/// Outline Radius
/// @param {Size} $radius [5px] - Radius
/// @require {mixin} css3-prefix

@mixin outline-radius($radius: 5px) {
  @include css3-prefix('outline-radius', $radius);
}


/// Resize
/// @param {*} $directoin [both] - Direction
/// @require {mixin} css3-prefix

@mixin resize($direction: both) {
  @include css3-prefix('resize', $direction);
}


/// Rotate
///
/// CSS Matrix Rotation Calculator http://www.boogdesign.com/examples/transforms/matrix-calculator.html
/// @param {Double} $deg [0] - Degree
/// @param {Double} $m11 [0] - M11
/// @param {Double} $m12 [0] - M12
/// @param {Double} $m21 [0] - M21
/// @param {Double} $m22 [0] - M22
/// @require {mixin} css3-prefix

@mixin rotate($deg: 0, $m11: 0, $m12: 0, $m21: 0, $m22: 0) {
  @include css3-prefix('transform', rotate($deg + deg));
  filter: progid:DXImageTransform.Microsoft.Matrix(
     M11=#{$m11}, M12=#{$m12}, M21=#{$m21}, M22=#{$m22}, sizingMethod='auto expand');
  zoom: 1;
}


/// Text Shadow
/// @param {Size} $x [2px] - X
/// @param {Size} $y [2px] - Y
/// @param {Size} $blur [2px] - Blur
/// @param {Color} $color [rgba(0,0,0,.4)] - Color

@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4)) {
  text-shadow: $x $y $blur $color;
}


/// Transform
/// @param {List} $params - Params
/// @require {mixin} css3-prefix

@mixin transform($params) {
  @include css3-prefix('transform', $params);
}


/// Transform-Origin
/// @param {List} $params - Params
/// @require {mixin} css3-prefix

@mixin transform-origin($params) {
  @include css3-prefix('transform-origin', $params);
}


// Transform-Style
/// @param {List} $params - Params
/// @require {mixin} css3-prefix

@mixin transform-style($style: preserve-3d) {
  @include css3-prefix('transform-style', $style);
}

/// Transition
/// @param {List} $properties - Properties
/// @require {mixin} css3-prefix

@mixin transition($properties...) {

  @if length($properties) >= 1 {
  @include css3-prefix('transition', $properties);
  }

  @else {
  @include css3-prefix('transition',  "all 0.2s ease-in-out 0s");
  }
}

@mixin transition-delay($properties...) {

  @if length($properties) >= 1 {
  @include css3-prefix('transition-delay', $properties);
  }

  @else {
  @include css3-prefix('transition-delay',  "all 0.2s ease-in-out 0s");
  }
}

 @mixin transition-duration($time:0.3s){
    @include css3-prefix('transition-duration',$time);
 }


/// Triple Borders
/// @param {Color} $colorOne [#3C3C3C] - Color One
/// @param {Color} $colorTwo [#999999] - Color Two
/// @param {Color} $colorThree [#000000] - Color Three
/// @param {Size} $radius [0] - Radius
/// @require {mixin} border-radius
/// @require {mixin} css3-prefix

@mixin triple-borders($colorOne: #3C3C3C, $colorTwo: #999999, $colorThree: #000000, $radius: 0) {
  border: 1px solid $colorOne;

  @include border-radius($radius);

  @include css3-prefix('box-shadow', "0 0 0 1px #{$colorTwo}, 0 0 0 2px #{$colorThree}");
}


/// Keyframes
/// @param {*} $animation-name - Animation name
/// @content [Animation css]

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
  @content;
  }
  @-moz-keyframes #{$animation-name} {
  @content;
  }
  @-ms-keyframes #{$animation-name} {
  @content;
  }
  @-o-keyframes #{$animation-name} {
  @content;
  }
  @keyframes #{$animation-name} {
  @content;
  }
}


/// Animation
/// @param {*} $str - name duration timing-function delay iteration-count direction fill-mode play-state ([http://www.w3schools.com/cssref/css3_pr_animation.asp](http://www.w3schools.com/cssref/css3_pr_animation.asp))
/// @require {mixin} css3-prefix

@mixin animation($str) {
  @include css3-prefix('animation', $str);
}


/* Border radius TOP LEFT */
@mixin border-top-left-radius($radius) {
  -webkit-border-top-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
}
/* Border radius TOP RIGHT */
@mixin border-top-right-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
} 

/* Border radius BOTTOM LEFT */
@mixin border-bottom-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
}
/* Border radius BOTTOM RIGHT */
@mixin border-bottom-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
}


/* MIXINS PER DISPLAY FLEX */
@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

%flexbox { @include flexbox; }

//----------------------------------

@mixin inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

%inline-flex { @include inline-flex; }

//----------------------------------------------------------------------

// Flexbox Direction
//
// The 'flex-direction' property specifies how flex items are placed in
// the flex container, by setting the direction of the flex container's
// main axis. This determines the direction that flex items are laid out in. 
//
// Values: row | row-reverse | column | column-reverse
// Default: row
//
// http://w3.org/tr/css3-flexbox/#flex-direction-property

@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}
  // Shorter version:
  @mixin flex-dir($args...) { @include flex-direction($args...); }

//----------------------------------------------------------------------

// Flexbox Wrap
//
// The 'flex-wrap' property controls whether the flex container is single-line
// or multi-line, and the direction of the cross-axis, which determines
// the direction new lines are stacked in. 
//
// Values: nowrap | wrap | wrap-reverse
// Default: nowrap
//
// http://w3.org/tr/css3-flexbox/#flex-wrap-property

@mixin flex-wrap($value: nowrap) {
  // No Webkit Box fallback.
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else { 
    -ms-flex-wrap: $value; 
  }
  flex-wrap: $value;
}

//----------------------------------------------------------------------

// Flexbox Flow (shorthand)
//
// The 'flex-flow' property is a shorthand for setting the 'flex-direction'
// and 'flex-wrap' properties, which together define the flex container's
// main and cross axes.
//
// Values: <flex-direction> | <flex-wrap> 
// Default: row nowrap
//
// http://w3.org/tr/css3-flexbox/#flex-flow-property

@mixin flex-flow($values: (row nowrap)) {
  // No Webkit Box fallback.
  -webkit-flex-flow: $values;
  -moz-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}

//----------------------------------------------------------------------

// Flexbox Order
//
// The 'order' property controls the order in which flex items appear within
// their flex container, by assigning them to ordinal groups.
//
// Default: 0
//
// http://w3.org/tr/css3-flexbox/#order-property

@mixin order($int: 0) {
  -webkit-box-ordinal-group: $int + 1;
  -webkit-order: $int;
  -moz-order: $int;
  -ms-flex-order: $int;
  order: $int;
}

//----------------------------------------------------------------------

// Flexbox Grow
//
// The 'flex-grow' property sets the flex grow factor. Negative numbers
// are invalid.
//
// Default: 0
//
// http://w3.org/tr/css3-flexbox/#flex-grow-property

@mixin flex-grow($int: 0) {
  -webkit-box-flex: $int;
  -webkit-flex-grow: $int;
  -moz-flex-grow: $int;
  -ms-flex-positive: $int;
  flex-grow: $int;
}

//----------------------------------------------------------------------

// Flexbox Shrink
//
// The 'flex-shrink' property sets the flex shrink factor. Negative numbers
// are invalid.
//
// Default: 1
//
// http://w3.org/tr/css3-flexbox/#flex-shrink-property

@mixin flex-shrink($int: 1) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex-negative: $int;
  flex-shrink: $int;
}

//----------------------------------------------------------------------

// Flexbox Basis
//
// The 'flex-basis' property sets the flex basis. Negative lengths are invalid. 
//
// Values: Like "width" 
// Default: auto
//
// http://www.w3.org/TR/css3-flexbox/#flex-basis-property

@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  -moz-flex-basis: $value;
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}

//----------------------------------------------------------------------

// Flexbox "Flex" (shorthand)
//
// The 'flex' property specifies the components of a flexible length: the
// flex grow factor and flex shrink factor, and the flex basis. When an
// element is a flex item, 'flex' is consulted instead of the main size
// property to determine the main size of the element. If an element is
// not a flex item, 'flex' has no effect.
//
// Values: none | <flex-grow> <flex-shrink> || <flex-basis>
// Default: See individual properties (1 1 0).
//
// http://w3.org/tr/css3-flexbox/#flex-property

@mixin flex($fg: 1, $fs: null, $fb: null) {
    
  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;

  // Box-Flex only supports a flex-grow value so let's grab the
  // first item in the list and just return that.
  @if type-of($fg) == 'list' {
    $fg-boxflex: nth($fg, 1);
  }

  -webkit-box-flex: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -moz-box-flex: $fg-boxflex;
  -moz-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}

//----------------------------------------------------------------------

// Flexbox Justify Content
//
// The 'justify-content' property aligns flex items along the main axis
// of the current line of the flex container. This is done after any flexible
// lengths and any auto margins have been resolved. Typically it helps distribute
// extra free space leftover when either all the flex items on a line are
// inflexible, or are flexible but have reached their maximum size. It also
// exerts some control over the alignment of items when they overflow the line.
//
// Note: 'space-*' values not supported in older syntaxes.
//
// Values: flex-start | flex-end | center | space-between | space-around
// Default: flex-start
//
// http://w3.org/tr/css3-flexbox/#justify-content-property

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;    
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}
  // Shorter version:
  @mixin flex-just($args...) { @include justify-content($args...); }

//----------------------------------------------------------------------

// Flexbox Align Items
//
// Flex items can be aligned in the cross axis of the current line of the
// flex container, similar to 'justify-content' but in the perpendicular
// direction. 'align-items' sets the default alignment for all of the flex
// container's items, including anonymous flex items. 'align-self' allows
// this default alignment to be overridden for individual flex items. (For
// anonymous flex items, 'align-self' always matches the value of 'align-items'
// on their associated flex container.) 
//
// Values: flex-start | flex-end | center | baseline | stretch
// Default: stretch
//
// http://w3.org/tr/css3-flexbox/#align-items-property

@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}

//----------------------------------

// Flexbox Align Self
//
// Values: auto | flex-start | flex-end | center | baseline | stretch
// Default: auto

@mixin align-self($value: auto) {
  // No Webkit Box Fallback.
  -webkit-align-self: $value;
  -moz-align-self: $value;
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}

//----------------------------------------------------------------------

// Flexbox Align Content
//
// The 'align-content' property aligns a flex container's lines within the
// flex container when there is extra space in the cross-axis, similar to
// how 'justify-content' aligns individual items within the main-axis. Note,
// this property has no effect when the flexbox has only a single line.
//
// Values: flex-start | flex-end | center | space-between | space-around | stretch
// Default: stretch
//
// http://w3.org/tr/css3-flexbox/#align-content-property

@mixin align-content($value: stretch) {
  // No Webkit Box Fallback.
  -webkit-align-content: $value;
  -moz-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}



@mixin align-items($align) {
  -webkit-box-align: $align;
  -webkit-align-items: $align;
  -ms-flex-align: $align;
  align-items: $align;
}

@mixin justify-content($justify) {
  -webkit-box-pack: $justify;
  -webkit-justify-content: $justify;
  -ms-flex-pack: $justify;
  justify-content: $justify;
}


/* TRANSFORM SCALE */
@mixin scale($scale) {
  -webkit-transform: scale($scale);
  -moz-transform: scale($scale);
  -ms-transform: scale($scale);
  -o-transform: scale($scale);
  transform: scale($scale);
}

/* BACKGROUND-SIZE */
@mixin bg-size($size) {
  -webkit-background-size: $size;
  -moz-background-size: $size;
  background-size: $size;
}

@mixin rotate($rotate) {
  -webkit-transform: rotate($rotate);
  -moz-transform: rotate($rotate);
  -ms-transform: rotate($rotate);
  -o-transform: rotate($rotate);
  transform: rotate($rotate);
}

@mixin rotateY($rotateY) {
  -webkit-transform: rotateY($rotateY);
  -moz-transform: rotateY($rotateY);
  -ms-transform: rotateY($rotateY);
  -o-transform: rotateY($rotateY);
  transform: rotateY($rotateY);
}

@mixin translateX($translateX) {
  -webkit-transform: translateX($translateX);
  -moz-transform: translateX($translateX);
  -ms-transform: translateX($translateX);
  -o-transform: translateX($translateX);
  transform: translateX($translateX);
}

@mixin translateY($translateY) {
  -webkit-transform: translateY($translateY);
  -moz-transform: translateY($translateY);
  -ms-transform: translateY($translateY);
  -o-transform: translateY($translateY);
  transform: translateY($translateY);
}

/*******************************************
 *
 * Typography Classes
 *
 *  ******************************************/

h1 {
	font-size: 0;
	margin: 0;
}

h2 {
	font-size: rem(16px);
	@include letter-spacing(150);
}

.prj-list {
	color: var(--var-c-white);
	font-size: vw(100px);
	line-height: rem(40px);

	@include media-breakpoint-up(md) {
		font-size: vw(70px);
		line-height: vw(70px);
	}

	.prj-item {
		border-top: 1px solid var(--var-c-white);

		&:last-child {
			border-bottom: 1px solid var(--var-c-white);
		}
	}

	a {
		display: block;
		font-weight: var(--var-f-regular);
		padding: rem(20px);
		position: relative;
		text-transform: none;

		// &:hover, &:focus {
		// 	color: var(--var-c-primary);
		// 	text-shadow: 1px 0 var(--var-c-white), -1px 0 var(--var-c-white), 0 1px var(--var-c-white), 0 -1px var(--var-c-white),
		// 			1px 1px var(--var-c-white), -1px -1px var(--var-c-white), 1px -1px var(--var-c-white), -1px 1px var(--var-c-white);
		// }

		&:hover {

			.ticker-wrap {
				opacity: 1;

				&:before {
					transform: scaleY(1);
    				transition-duration: 0.5s;
				}
			}

		}
	}


	// testo scorrevole
	.ticker-wrap {
		@include align-items(center);
		bottom: 0;
		@include flexbox;
		left: 0;
		margin: 0 auto;
		opacity: 0;
		overflow: hidden;
		padding: rem(20px);
		position: absolute;
		right: 0;
		top: 0;
		@include transition(0.5s);
		white-space: nowrap;
		width: 100%;

		&:before {
			background-color: var(--var-c-secondary);
			bottom: 0;
			content: "";
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			transform: scaleY(0);
		}
	}
	.ticker {
		animation: marquee 80s linear infinite;
		display: inline-block;
	}
	.item-collection-1 {
		animation: swap 80s linear infinite;
		left: 0%;
		position: relative;
	}

	.item {
		color: var(--var-c-secondary);
		display: inline-block;
		font-size: rem(40px);
		margin-bottom: 0;
		padding-left: rem(60px);
		padding-right: rem(24px);
		position: relative;

		text-shadow: 1px 0 var(--var-c-white), -1px 0 var(--var-c-white), 0 1px var(--var-c-white), 0 -1px var(--var-c-white),
					1px 1px var(--var-c-white), -1px -1px var(--var-c-white), 1px -1px var(--var-c-white), -1px 1px var(--var-c-white);

		@include media-breakpoint-up(md) {
			font-size: vw(70px);
		}

		&:before {
			background-size: contain;
			content: "/";
			left: 0;
			position: absolute;
			top: 0;
		}
	}
}
@keyframes marquee {
	0% {
		transform: translateX(0)
	}
	100% {
		transform: translateX(-100%)
	}
}

@keyframes swap {
	0%, 50% {
		left: 0%;
	}
	50.01%,
	100% {
		left: 100%;
	}
}

a {
	color: var(--var-c-white);
	font-weight: var(--var-f-black);
	text-decoration: none;
	@include transition(0.3s);

	&:hover, &:focus {
		color: var(--var-c-white);
	}
}


footer {
	@include letter-spacing(250);
	font-weight: var(--var-f-black);
}